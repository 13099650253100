import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
import ReCAPTCHA from "react-google-recaptcha"
import { Axios, db } from "../firebaseConfig"
import firebase from "firebase/app"

const recaptchaRef = React.createRef()
export default class Contact extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {},
      errors: {},
      activeError: false,
      recaptchaValue: null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  handleChange(field, e) {
    let fields = this.state.fields
    let target = e.target
    let value = target.type === "checkbox" ? target.checked : target.value
    fields[field] = value

    this.setState({
      fields,
    })
  }

  handleValidation() {
    let fields = this.state.fields
    let errors = {}
    let formIsVaild = true

    //name
    if (!fields["firstName"] || fields["firstName"] === "") {
      formIsVaild = false
      errors["name"] = "Name cannot be empty !"
    }

    // Mobile Number
    if (!fields["mobileNumber"] || fields["mobileNumber"] === "") {
      formIsVaild = false
      errors["mobileNumber"] = "Phone number cannot be empty !"
    } else {
      if (typeof fields["mobileNumber"] !== "undefined") {
        let regEx = new RegExp(
          /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|5|6|7|8)\d)\d{6}$/
        )
        if (!regEx.test(fields["mobileNumber"])) {
          formIsVaild = false
          errors["mobileNumber"] = "Please enter valid phone number"
        }
      }
    }

    // Email Address
    if (!fields["email"] || fields["email"] === "") {
      formIsVaild = false
      errors["email"] = "Email cannot be empty !"
    } else {
      if (typeof fields["email"] !== "undefined") {
        let regEx = new RegExp(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        )
        if (!regEx.test(fields["email"])) {
          formIsVaild = false
          errors["email"] = "Please enter a valid email address"
        }
      }
    }

    //reCaptcha
    const recaptchaValue = recaptchaRef.current.getValue()
    {
      this.state.recaptchaValue = recaptchaValue
    }
    console.log(this.state.recaptchaValue)

    if (this.state.recaptchaValue === "") {
      formIsVaild = false
      errors["cap"] = "Please verify that you are a human."
    }

    this.setState({ errors: errors, activeError: true })
    return formIsVaild
  }

  async submitInquiry(data) {
    let newData = Object.assign({}, data)
    newData.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    newData.isCompleted = false

    await db.collection("inquiries").add(newData)
  }

  async onSubmit(event) {
    event.preventDefault()
    let data = { ...this.state.fields }
    if (this.handleValidation()) {
      this.setState({
        loading: true,
      })

      try {
        await this.submitInquiry(data)
        console.log("submited")
      } catch (err) {
        console.log(err)
      }
    } else {
      console.log("Form has errors")
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query Contact {
            contactBg: file(relativePath: { eq: "contact/contactBg.jpg" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        `}
        render={data => {
          const images = {
            contactBg: convertToBgImage(getImage(data.contactBg)),
          }

          // const recaptchaRef = React.createRef()
          // const onSubmit = event => {
          //   const recaptchaValue = recaptchaRef.current.getValue()
          //   {
          //     this.state.recaptchaValue = recaptchaValue
          //   }
          //   event.preventDefault()
          //   let data = { ...this.state.fields }
          //   if (this.handleValidation()) {
          //     console.log("Submited")
          //     console.log(this.state.fields)
          //   } else {
          //     console.log("Form has errors")
          //   }
          // }

          return (
            <Layout>
              <Seo title="Contact | Tech Pacific Lanka" />
              <section>
                <div className="main">
                  <BackgroundImage {...images.contactBg} className="sap-header">
                    <div className="container">
                      <div className="sap-header-content">
                        <div className="header-title success-title">
                          Connect with Tech Pacific
                        </div>
                        <div className="header-description">
                          For over a decade, Tech Pacific has successfully
                          utilized state-of-the-art digital technology to help
                          large and small enterprises level up. Reach out to us
                          with your questions and queries on how Tech Pacific
                          can help transform your business into an intelligent
                          enterprise of the future.
                        </div>

                        <span>
                          {/* <div className="learn-more mt-2 success">
                            LEARN MORE
                            <div className="icon">
                              <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                            </div>
                          </div> */}
                        </span>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
              </section>
              <section>
                <div className="communicate-section">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4 item-1">
                        <div className="contact-item">
                          <StaticImage
                            width={60}
                            className="mb-3"
                            src="../images/contact/Icon_contactus.png"
                            alt="tachpac"
                          />

                          <div className="description">Call 011 488 7877 </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="contact-item">
                          <StaticImage
                            width={60}
                            className="mb-3"
                            src="../images/contact/Icon_address.png"
                            alt="tachpac"
                          />

                          <div className="description">
                            {" "}
                            No.4, Castle Lane, Colombo 4, Sri Lanka{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 item-3">
                        <div className="contact-item">
                          <StaticImage
                            width={60}
                            className="mb-3"
                            src="../images/contact/Icon_web.png"
                            alt="tachpac"
                          />

                          <div className="description"> info@techpac.lk </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* form start */}

              <section>
                <div className="contact-form">
                  <div className="container">
                    <form action="" onSubmit={this.onSubmit}>
                      <div className="row">
                        <div className="col-md-4">
                          <input
                            onChange={this.handleChange.bind(this, "firstName")}
                            value={this.state.fields["firstName"] || ""}
                            name="firstName"
                            type="text"
                            placeholder="First Name"
                            className="short-input form-mobile"
                          />
                          {this.state.errors["name"] ? (
                            <div
                              className={
                                this.state.activeError
                                  ? "input-error active"
                                  : "input-error"
                              }
                            >
                              <span>{this.state.errors["name"]}</span>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4">
                          <input
                            onChange={this.handleChange.bind(this, "lastName")}
                            value={this.state.fields["lastName"] || ""}
                            name="lastName"
                            type="text"
                            placeholder="Last Name"
                            className="short-input form-mobile"
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            onChange={this.handleChange.bind(this, "email")}
                            value={this.state.fields["email"]}
                            name="email"
                            type="text"
                            placeholder="Email"
                            className="short-input"
                          />
                          {this.state.errors["email"] ? (
                            <div
                              className={
                                this.state.activeError
                                  ? "input-error active"
                                  : "input-error"
                              }
                            >
                              <span>{this.state.errors["email"]}</span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mid">
                        <div className="col-md-4">
                          <input
                            onChange={this.handleChange.bind(
                              this,
                              "mobileNumber"
                            )}
                            value={this.state.fields["mobileNumber"]}
                            type="text"
                            placeholder="Phone No"
                            className="short-input form-mobile"
                            name="mobileNumber"
                          />
                          {this.state.errors["mobileNumber"] ? (
                            <div
                              className={
                                this.state.activeError
                                  ? "input-error active"
                                  : "input-error"
                              }
                            >
                              <span>{this.state.errors["mobileNumber"]}</span>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4">
                          <input
                            onChange={this.handleChange.bind(this, "jobTitle")}
                            value={this.state.fields["jobTitle"] || ""}
                            name="jobTitle"
                            type="text"
                            placeholder="Job Title"
                            className="short-input form-mobile"
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            onChange={this.handleChange.bind(this, "company")}
                            value={this.state.fields["company"] || ""}
                            name="company"
                            type="text"
                            placeholder="Company/Organization"
                            className="short-input"
                          />
                        </div>
                      </div>
                      <div className="row mid-bottom">
                        <div className="col-md-6">
                          <div className="inquiry-type-title">Inquiry Type</div>
                          <select
                            className="short-input option-text"
                            onChange={this.handleChange.bind(
                              this,
                              "productCategory"
                            )}
                            value={this.state.fields["productCategory"] || ""}
                            name="productCategory"
                          >
                            <option value="Digital Transformation">
                              ERP (SAP)
                            </option>
                            <option value="SAP">
                              Module Data Centers (Vertiv)
                            </option>
                            <option value="Digital Infrastructure">
                              Applications
                            </option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <div className="inquiry-type-title">Industry </div>
                          <select
                            className="short-input option-text"
                            onChange={this.handleChange.bind(
                              this,
                              "productSubCategory"
                            )}
                            value={
                              this.state.fields["productSubCategory"] || ""
                            }
                            name="productSubCategory"
                          >
                            <option>Travel & Hospitality</option>
                            <option>Chemicals</option>
                            <option>Mill Products</option>
                            <option>Consumer Products</option>
                            <option>Professional Services</option>
                            <option>Oil and Gas</option>
                            <option>Automotive</option>
                            <option>Retail</option>
                            <option>Telecommunication</option>
                            <option>Healthcare</option>
                            <option>Real estate & Construction</option>
                            <option>Wholesale Distribution</option>
                            <option>Supply chain & Logistics</option>
                            <option>Apparel</option>
                            <option>Agriculture</option>
                            <option>Exports & Imports</option>
                            <option>Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mid-bottom"></div>
                      <div className="row mid-bottom">
                        <div className="col-md-12">
                          <textarea
                            onChange={this.handleChange.bind(this, "comments")}
                            value={this.state.fields["comments"] || ""}
                            name="comments"
                            type="text"
                            placeholder="Comments"
                            className="short-input"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-flex flex-column">
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey="6LdT5W8cAAAAAO74Zl1sTp4G7R9cHelPkZ66ztpg"
                            />
                            {this.state.errors["cap"] ? (
                              <div
                                className={
                                  this.state.activeError
                                    ? "input-error active"
                                    : "input-error"
                                }
                              >
                                <span>{this.state.errors["cap"]}</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6 mt-auto">
                          <div className="submit-button ">
                            <button type="submit">Submit</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>

              {/* form end */}

              <section>
                <div className="further-section">
                  <div className="container">
                    <div className="common-title mb-4">
                      For Further Inquiries
                    </div>
                    <div className="row pb-4">
                      <div className="col-md-1 mobile-alignment">
                        <StaticImage
                          src="../images/sap/sap-header-logo.png"
                          width={100}
                          alt="tachpac"
                        />
                      </div>
                      <div className="col-md-2 mobile-alignment">
                        <StaticImage
                          src="../images/products/digital-infrastructure/LOGO.jpg"
                          width={135}
                          alt="tachpac"
                        />
                      </div>
                    </div>
                    <div className="row pb-4 ">
                      <div className="col-md-2 mobile-alignment">
                        <StaticImage
                          src="../images/products/applications/adobe.png"
                          width={150}
                          alt="tachpac"
                        />
                      </div>
                      <div className="col-md-2 mobile-alignment">
                        <StaticImage
                          src="../images/products/applications/siemens.png"
                          width={150}
                          alt="tachpac"
                        />
                      </div>
                      <div className="col-md-2 mobile-alignment">
                        <StaticImage
                          src="../images/products/applications/corel_d.png"
                          width={150}
                          alt="tachpac"
                        />
                      </div>
                      <div className="col-md-2 mobile-alignment">
                        <StaticImage
                          src="../images/products/applications/team_viewer.png"
                          width={150}
                          alt="tachpac"
                        />
                      </div>
                    </div>
                    <div className="contact-details">
                      <div className="mail">
                        <a href="mailto:info@techpac.lk">info@techpac.lk</a>
                      </div>
                      <div className="number">
                        <a href="tel:+94114887877">011 488 7877</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Layout>
          )
        }}
      ></StaticQuery>
    )
  }
}
