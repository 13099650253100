import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/firestore"
import Axios from "axios"

const firebaseConfig = {
  apiKey: "AIzaSyAIo11-ER9AwYCnFHH1pNqlN9C7m-z_454",
  authDomain: "tech-pacific-lanka-95f0f.firebaseapp.com",
  projectId: "tech-pacific-lanka-95f0f",
  storageBucket: "tech-pacific-lanka-95f0f.appspot.com",
  messagingSenderId: "486365638820",
  appId: "1:486365638820:web:5b11715c4dfe35e3e2f631",
  measurementId: "G-HSK5D0HQXJ",
}

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()

export { Axios, db }
